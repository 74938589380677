import React, { useState } from "react";
import AboutBanner from "../img/banner/realestate-banner.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import "../Css/About.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import "../Css/Home.css";
import Footer from "../Components/Footer";
import { MetaTags } from "react-meta-tags";

import CountUp from "react-countup";
import resort from "../img/Clubhouse-20240113T064329Z-001/Clubhouse/2. About us 2.jpg";

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="text">
      {isReadMore ? text.slice(70, 200) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...read more" : " show less"}
      </span>
    </p>
  );
};

const About = () => {
  return (
    <>
    {/* ========= Seo start========== */}
    <MetaTags>
        <title>Pure Veg Resorts in Thane | Dreamland Resort</title>
        <meta
          name="description"
          content="Experience culinary delight at Pure Veg Resorts in Thane. Immerse yourself in a gastronomic retreat, where every bite is a journey of flavors. "
        />
        <meta
          property="title"
          content="Pure Veg Resorts in Thane | Dreamland Resort"
        />
        <meta
          property="og:title"
          content="Pure Veg Resorts in Thane | Dreamland Resort"
        />
       <meta
          name="og:description"
          content="Experience culinary delight at Pure Veg Resorts in Thane. Immerse yourself in a gastronomic retreat, where every bite is a journey of flavors. "
        />
        <meta
          name="keywords"
          content="Best Resort in Thane,
          Pure Veg Resorts in Thane,
          Pure Jain Resort in Thane,
          Resort with Private Room in Thane,
           Best Private Villa in Thane,
          Resort with Cottages Room in Thane,
          Veg Resort near Thane,
          Plot for Sale in Thane,
          Resort for Weddings in Thane,
          Top Resort in Thane,
          Best Resort with Dinning in Thane"
        />
        <link rel="canonical" href="https://dreammland.com/pure-veg-resorts-thane" />
      </MetaTags>
      {/* ======== Seo end ======= */}
      
      {/* ======= About Banner ======= */}
      <section className="about-section">
        <div className="about-bottom-pattern" />
        <div className="aboutBanner">
          <div className="container">
            <div className="aboutbannerTxt">
            <h1 style={{color:"#fff", fontSize:'10px'}}>Pure Veg Resorts in Thane</h1>
              <h2>
                About Dreammland
              </h2>
              <p>
                
              </p>
            </div>
          </div>
        </div>
      </section>
      {/* === About Section ===== */}
      <section className="about-manager">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-5 col-sm-12">
              <div className="manager-img">
                <img src={resort} alt="pure veg resorts" />
              </div>
            
            </div>
            <div className="col-lg-8 col-md-7 col-sm-12">
              <div className="manager-desc">
              
                <h2>
                  About Dreammland
                </h2>
                <p>The story of Dreammland, the pure Veg and Jain resort near Mumbai , Kasara is a tale of remarkable expansion and single-minded dedication to the highest of standards. It is a tale of Mr. Mukesh Shah and his vision to build castles in the air that rise high on land - almost touching the sky. The dream & ideas started taking shape from 2007. And the Journey started in the right direction by the name “Dreammland” the pure Veg and Jain resort near Mumbai.


                {/* The story of Dreammland Resort at Sirol, Kasara is a tale of remarkable expansion and single-minded dedication to the highest of standards. It is a tale of Mr. Mukesh Shah and his vision to build castles in the air that rise high on land - almost touching the sky. The dream & ideas started taking shape from 2007. And the Journey started in the right direction by the name “Dreammland”. */}

                </p>
                <p>Today, we are one of the leading players in several segments ranging from Bungalows, Villas, Apartments, Budget Homes, N.A plots and Farm Plots in the real estate arena.

                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    
      {/* ======= Counter ======= */}
      <div className="about-count">
        <div className="container">
          <div class="headTitle">
            <div class="headTitle">
              <h2>We Are Excellent In</h2>
              <p>
                Dreammland Resort located near Kundan Dam, Sirol, blessed by
                nature and mountains all around. with 40+Acres of land, Plotted
                and constructed with top amenities like Swimming Pool with
                Jacuzzi, Indoor/Outdoor Games, Landscape Garden, Play Park, Club
                House, Restaurant, Row House, Bungalows, Cottages, Studio
                Apartment and much more.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3 col-6 mb-5">
              <div className="counter left_icn">
                <i class="fa fa-calendar" aria-hidden="true"></i>
                <span className="timer">
                  <span>
                    <CountUp end={60} duration={10} />
                  </span>
                </span>
                <h6>Rooms</h6>
              </div>
            </div>
            <div className="col-md-3 col-6 mb-5">
              <div className="counter left_icn">
                <i class="fa fa-users" aria-hidden="true"></i>
                <span className="timer">
                  <span>
                    <CountUp end={10} duration={5} />
                  </span>
                </span>


                <h6>Villas</h6>
              </div>
            </div>
            <div className="col-md-3 col-6 mb-5">
              <div className="counter left_icn">
                <i className="fa fa-smile-o" aria-hidden="true" />
                <span className="timer">
                  <span>
                    <CountUp end={400} duration={5} />
                  </span>
                </span>
                <h6>Capacity </h6>
              </div>
            </div>
            <div className="col-md-3 col-6 mb-5">
              <div className="counter left_icn">
                <i className="fa fa-comments" aria-hidden="true" />
                <span className="timer">
                  <span>
                    <CountUp end={ 12} duration={5} />
                  </span>
                </span>
                <h6>
Activities</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ===== Testional === */}
      <section className="testimonials-section">
        <div className="pattern-top" />
        <div className="container">
          <div class="headTitle">
            <h2>Testimonal</h2>
            <p>
              {/* Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi
              tempora distinctio rerum libero laudantium assumenda. */}
            </p>
          </div>
          <Swiper
            slidesPerView={1}
            spaceBetween={10}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 2,
                spaceBetween: 30,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 30,
              },
            }}
            // pagination={{
            //   clickable: true,
            // }}
            navigation={true}
            modules={[ Navigation]}
            className="Testimonal"
          >
           
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-block">
                  <div className="inner">
                    <div className="content-box">
                      <div className="content">
                        <div className="quote-icon">
                          <span className="flaticon-quote-1" />
                        </div>
                        <div className="text">
                        The resort's jacuzzi swimming pool is a true highlight, providing relaxation and fun for all. 
                        As a vegetarian, I was thrilled to see an extensive selection of pure vegetarian and Jain food options, 
                        catering to various tastes. The staff deserves a special mention for their warmth and professionalism. 
                        Our group had a blast with the exciting activities they offered, creating unforgettable memories. 
                        The rooms were well-appointed and cleaned regularly, ensuring a comfortable stay. And the icing on 
                        the cake was the mesmerizing tracking point with the water dam view, surrounded by the beauty of 
                        majestic mountains. It's a dream destination!"

                        </div>
                      </div>
                    </div>
                    <div className="info">
                     
                      <div className="name">GEETA BHANUSHALI</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-block">
                  <div className="inner">
                    <div className="content-box">
                      <div className="content">
                        <div className="quote-icon">
                          <span className="flaticon-quote-1" />
                        </div>
                        <div className="text">
                        This place is a paradise for group vacations! The amenities are outstanding with a jacuzzi 
                        swimming pool that adds a touch of luxury to your stay. As a pure vegetarian, I was thrilled to 
                        find that they also cater to Jain dietary preferences, ensuring everyone's needs are met. 
                        The staff here are incredibly polite and attentive, making us feel right at home. Our group 
                        had a blast participating in various activities offered by the resort. The rooms were 
                        well-maintained and spotlessly clean, ensuring a comfortable and enjoyable stay. Moreover, 
                        the highlight of our trip was the breathtaking tracking point with a stunning water dam view. 
                        The sight of the majestic mountains was simply awe-inspiring. Highly recommended!"

                        </div>
                      </div>
                    </div>
                    <div className="info">
                      
                      <div className="name">JUGAL MEHTA</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>

            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-block">
                  <div className="inner">
                    <div className="content-box">
                      <div className="content">
                        <div className="quote-icon">  
                          <span className="flaticon-quote-1" />
                        </div>
                        <div className="text">
                        "Our group had an amazing time at this place! The facilities were top-notch, and the jacuzzi 
                        swimming pool added an extra touch of luxury to our vacation. Being pure vegetarians, 
                        we were delighted to find a variety of Jain food options available, making our dining 
                        experience perfect. The staff was generally polite and attentive, although there were a 
                        few instances of delayed service. However, it didn't overshadow the fun we had with the 
                        numerous activities available. The rooms were good, but could use a bit of improvement in 
                        terms of cleanliness. Nonetheless, the breathtaking tracking point near the water dam was 
                        unforgettable, and we cherished the panoramic mountain views."

                        </div>
                      </div>
                    </div>
                    <div className="info">
                     
                      <div className="name">Komal Jethva</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="owl-item">
                <div className="testimonial-block">
                  <div className="inner">
                    <div className="content-box">
                      <div className="content">
                        <div className="quote-icon">
                          <span className="flaticon-quote-1" />
                        </div>
                        <div className="text">
                        The property is very homely and beautiful....The villa gives you a serene feeling, very 
                        neat and clean...The food is very delicious. They have jain and non non jain food...care was 
                        taken to serve both cuisines with our choices being their priority....
                        The hospitality was great and we felt very special...
                     Especially during monsoon you get an amazing weather and one can trek towards the dam and 
                     find a hidden waterfall

                        </div>
                      </div>
                    </div>
                    <div className="info">
                     
                      <div className="name">Chirag Gangani</div>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* <div className="pattern-bottom" /> */}
      </section>
      <Footer />
    </>
  );
};

export default About;
